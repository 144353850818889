@media (min-height: 723px) {
    .ui.footer.form-page { /* Increased specificity for SO snippet priority */
      position: absolute;
      bottom: 0;
      width: 100%;
    }
}



